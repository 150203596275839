import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import Product from '../pages/Product';
import './shop.css';
import './home.css';
import saleBanner from '../components/assets/Sale Banner.png';
import backpackImage from '../components/assets/backpack.png';
import phonebanner from '../components/assets/phonebanner.png';

const Shop = () => {
  const [products, setProducts] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedPriceRange, setSelectedPriceRange] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [lock, setLock] = useState(false);
  const productsPerPage = 16;
  const { addToCart } = useCart();
  const navigate = useNavigate();

  // Array of slide image URLs
  const slides = [saleBanner];

  // Function to handle next slide
  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  };

  // Function to navigate to specific slide
  const goToSlide = (slideIndex) => {
    setCurrentSlide(slideIndex);
  };

  // Effect to start and stop interval
  useEffect(() => {
    const interval = setInterval(nextSlide, 3000);
    return () => clearInterval(interval);
  }, [currentSlide]);

  // Effect to fetch products and featured products
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/products`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    const fetchFeaturedProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/featured-products`);
        setFeaturedProducts(response.data);
      } catch (error) {
        console.error('Error fetching featured products:', error);
      }
    };

    fetchProducts();
    fetchFeaturedProducts();
  }, []);

  const handleAddToCart = (product) => {
    if (!lock) {
      setLock(true);
      addToCart(product);
      setTimeout(() => {
        setLock(false);
      }, 500);
    }
  };

  const handleBannerClick = () => {
    navigate('/shop');
  };

  const renderProducts = (productsList) => {
    const filteredProducts = productsList.filter((product) =>
      product.colors.some(
        (color) =>
          [0].includes(color.priority) &&
          color.images.length > 0 &&
          (!selectedPriceRange || product.sellingPrice < selectedPriceRange)
      )
    );

    const paginatedProducts = filteredProducts.slice(
      currentPage * productsPerPage,
      (currentPage + 1) * productsPerPage
    );

    return (
      <div className="product-container">
        {paginatedProducts.map((product) =>
          product.colors.map((color) => {
            if ([0].includes(color.priority) && color.images.length > 0) {
              return (
                <Product
                  key={`${product._id}-${color.color}`}
                  product={product}
                  color={color}
                  onAddToCart={handleAddToCart}
                  onVisible={() => {
                    /* Optionally handle visibility */
                  }}
                />
              );
            } else {
              return null;
            }
          })
        )}
      </div>
    );
  };

  return (
    <div>
      {/* PC Banner */}
      <div onClick={handleBannerClick}>
        <section className="section1">
          <div className="img-slider">
            {slides.map((slide, index) => (
              <img
                key={index}
                src={slide}
                alt={`Slide ${index}`}
                className={index === currentSlide ? 'img active' : 'img'}
              />
            ))}
          </div>
          {/* Add the backpack image here */}
          <img src={backpackImage} alt="Backpack" className="backpack-image" />
         {/* </div>
          <div className="navigation-dots">
            {slides.map((slide, index) => (
              <div
                key={index}
                className={index === currentSlide ? 'dot active' : 'dot'}
                onClick={() => goToSlide(index)}
              ></div>
            ))}
          </div>  */}
        </section>
      </div>

      {/* Phone Banner */}
      <div className="phone-banner" onClick={handleBannerClick}>
        <img src={phonebanner} alt="Phone Sale Banner" />
      </div>

      <div>
        <h1>BEST SELLING PRODUCTS</h1>
        {renderProducts(products)}
      </div>
    </div>
  );
};

export default Shop;
