import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './userorder.css';

const AdminReturnOrders = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [yearFilter, setYearFilter] = useState('');
  const [monthFilter, setMonthFilter] = useState('');
  const [dayFilter, setDayFilter] = useState('');
  const [selectedReturnOrder, setSelectedReturnOrder] = useState(null);

  useEffect(() => {
    const fetchUsersReturnOrders = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/admin/users-return-orders', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.users) {
          setUsers(response.data.users);
        } else {
          alert('No users or return orders found.');
        }
      } catch (error) {
        console.error('Error fetching users and return orders:', error);
        alert('An error occurred while fetching users and return orders.');
      } finally {
        setLoading(false);
      }
    };

    fetchUsersReturnOrders();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === 'year') setYearFilter(value);
    if (name === 'month') setMonthFilter(value);
    if (name === 'day') setDayFilter(value);
  };

  const handleReturnOrderClick = (returnOrder) => {
    setSelectedReturnOrder(returnOrder);
  };

  // Apply filtering to return orders
  const filteredUsers = users
    .map(user => ({
      ...user,
      returnOrders: user.returnOrders
        .filter(returnOrder => {
          const returnOrderDate = new Date(returnOrder.returnDate);
          const returnOrderYear = returnOrderDate.getFullYear().toString();
          const returnOrderMonth = (returnOrderDate.getMonth() + 1).toString().padStart(2, '0');
          const returnOrderDay = returnOrderDate.getDate().toString().padStart(2, '0');

          return (
            (!yearFilter || returnOrderYear === yearFilter) &&
            (!monthFilter || returnOrderMonth === monthFilter) &&
            (!dayFilter || returnOrderDay === dayFilter)
          );
        })
        .sort((a, b) => new Date(a.returnDate) - new Date(b.returnDate))
    }))
    .filter(user => user.returnOrders.length > 0);

  if (loading) {
    return <p>Loading users and return orders...</p>;
  }

  return (
    <div className="admin-user-return-orders-container">
      <h2>All Users' Return Orders</h2>
      <div className="filter-options">
        <label>Filter by Year: </label>
        <input
          type="number"
          name="year"
          value={yearFilter}
          onChange={handleFilterChange}
          placeholder="YYYY"
        />

        <label>Filter by Month: </label>
        <input
          type="number"
          name="month"
          value={monthFilter}
          onChange={handleFilterChange}
          placeholder="MM"
          min="1"
          max="12"
        />

        <label>Filter by Day: </label>
        <input
          type="number"
          name="day"
          value={dayFilter}
          onChange={handleFilterChange}
          placeholder="DD"
          min="1"
          max="31"
        />
      </div>
      {filteredUsers.length === 0 ? (
        <p>No users or return orders found.</p>
      ) : (
        <table className="return-orders-table">
          <thead>
            <tr>
              <th>User</th>
              <th>Order ID</th>
              <th>Phone Number</th>
              <th>Return Date</th>
              <th>Reason</th>
              <th>Returned Items</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map(user => (
              user.returnOrders.map(returnOrder => (
                <tr key={returnOrder.orderId} onClick={() => handleReturnOrderClick(returnOrder)}>
                  <td>{user.fname} {user.lname} ({user.username})</td>
                  <td>{returnOrder.orderId}</td>
                  <td>{returnOrder.phoneNumber}</td>
                  <td>{new Date(returnOrder.returnDate).toLocaleDateString()}</td>
                  <td>{returnOrder.reason}</td>
                  <td>
                    <ul>
                      {returnOrder.returnedItems?.map((item, index) => (
                        <li key={index}>
                          {item.itemName}
                          {item.imageUrl && <img src={item.imageUrl} alt={item.itemName} />}
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminReturnOrders;
