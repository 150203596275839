import React, { useState } from 'react';
import './footer.css';
import freeDelivery from '../components/assets/freedelivery.png';
import cashOn from '../components/assets/cod.png';
import domesticShipping from '../components/assets/delivery.png';
import qualityGuarantee from '../components/assets/quality.png';
import easyReturn from '../components/assets/refund1.png';
import linkedinImg from '../components/assets/linkedin.png';
import instaImg from '../components/assets/instagram.png';
import facebookImg from '../components/assets/facebook.png';
import creditImg from '../components/assets/credit-card.png';


const Footer=()=>{
    return  (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-item">
                    <img src={creditImg} alt="sign up" />
                    <p>Secure Checkout</p>
                </div>
                <div className="footer-item">
                    <img src={freeDelivery} alt="Free Shipping" />
                    <p>Free Shipping</p>
                </div>
                <div className="footer-item">
                    <img src={easyReturn} alt="Quality Guaranteed" />
                    <p>Easy Return within 4-5 days</p>
                </div>
                <div className="footer-item">
                    <img src={cashOn} alt="Cash On Delivery" />
                    <p>Cash On Delivery</p>
                </div>
                <div className="footer-item">
                    <img src={domesticShipping} alt="5-7 Days Domestic Shipping" />
                    <p>5-7 Days Domestic Shipping</p>
                </div>
                <div className="footer-item">
                    <img src={qualityGuarantee} alt="Quality Guaranteed" />
                    <p>Quality Guaranteed</p>
                </div>
            </div>

            <div className='Policy'>
                <ul>
                    <a href='/terms-and-conditions'><li>Terms and Conditions</li></a>
                    <a href='/Privacy-policy'><li>Privacy Policy</li></a>
                    <a href='/return-policy'><li>Return Policy</li></a>
                    <a href='/about-us'><li>About Us</li></a>
                    <a href='/contact-us'><li>Contact Us</li></a>
                </ul>
            </div>


            <div className="footer-container">
                <div className="footer-section">
                    
                    <h3>FOLLOW</h3>
                    <div className="social-icons-footer">
                        <a href="#"><img src={linkedinImg} alt="Linkedin" /></a>
                        <a href="#"><img src={instaImg} alt="Instagram" /></a>
                        <a href="#"><img src={facebookImg} alt="Facebook" /></a>
                    </div>
                   
                </div>
                <div className="footer-section">
                    
                    <address>
                        <p>Address:-</p>
                        <p>212B Floor-GRND</p>
                        <p>Abubakar Bagicha Compound</p>
                        <p>Mumbai PIN:400017</p>
                    </address>
                    <div className="contacts">
                        <p>Email: info@westernflex.com</p>
                        <p>Phone: +91 7709037498</p>
                    </div>  
                </div>
            </div>
        </footer>
    );
}


export default Footer;
