import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './productDetails.css';
import { useCart } from '../contexts/CartContext';
import offerTag from '../components/assets/offer.png';
import AllReviews from './AllReviews';
import RelatedProducts from './RelatedProducts'; // Import RelatedProducts component
import { FaShareAlt } from 'react-icons/fa';

const ProductDetails = () => {
  const { productId, color } = useParams(); // Extract URL parameters
  const navigate = useNavigate();
  const { addToCart } = useCart();

  const [product, setProduct] = useState(null);
  const [mainImage, setMainImage] = useState('');
  const [selectedColorImages, setSelectedColorImages] = useState([]);
  const [selectedColor, setSelectedColor] = useState('');

  // Base URL from environment variables
  const baseUrl = process.env.REACT_APP_BASE_URL ;
  const shareLink = process.env.REACT_APP_SHARE_LINK;

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`${baseUrl}/product/${productId}/${color}`);
        setProduct(response.data);
        setMainImage(`${baseUrl}/${response.data.selectedColorImages[0]}`);
        setSelectedColorImages(response.data.selectedColorImages);
        setSelectedColor(response.data.selectedColor);
      } catch (error) {
        console.error('Error fetching product details:', error);
        navigate('/'); // Redirect to home page if there is an error
      }
    };

    fetchProductDetails();
  }, [productId, color, navigate, baseUrl]);

  if (!product) {
    return <div>Loading...</div>; // Loading state while fetching product data
  }

  const { title, name, shortDescription, listingPrice, sellingPrice, details, longDescription, about, allColors, categoryId } = product;

  const handleAddToCart = () => {
    const productData = {
      image: mainImage,
      title: name, // Use the product name as the title
      description: shortDescription,
      listingPrice,
      sellingPrice,
      details: longDescription,
      about,
      images: selectedColorImages,
      color: selectedColor,
      productId,
    };
    addToCart(productData);
  };

  const handleBuyNow = () => {
    const productData = {
      image: mainImage,
      title: name,
      description: shortDescription,
      listingPrice,
      sellingPrice,
      details: longDescription,
      about,
      images: selectedColorImages,
      color: selectedColor,
      productId,
    };


    if (localStorage.getItem('token')) {
      addToCart(productData);
      navigate('/checkout');
    } else {
      localStorage.setItem('pendingProduct', JSON.stringify(productData));
      navigate('/register');
    }
  };

  const handleColorChange = (colorImages, colorName) => {
    setSelectedColorImages(colorImages);
    setSelectedColor(colorName);
    setMainImage(`${baseUrl}/${colorImages[0]}`);
  };

  const percentageDifference = Math.round(((listingPrice - sellingPrice) / listingPrice) * 100);

  const shareProduct = () => {
    const shareUrl = `${shareLink}/product/${productId}/${selectedColor}`;
    if (navigator.share) {
      navigator.share({
        title: name,
        url: shareUrl,
      }).catch(console.error);
    } else {
      window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(name)}`, '_blank');
    }
  };

  return (
    <div className="product-details-page">
      <div className="product-details-container">
        <div className="image-gallery">
          <div className="thumbnail-container">
            {selectedColorImages.map((img, index) => (
              <img
                key={index}
                src={`${baseUrl}/${img}`}
                alt={`${name} ${index + 1}`}
                className="thumbnail"
                onMouseOver={() => setMainImage(`${baseUrl}/${img}`)}
              />
            ))}
          </div>
  
          <div className="main-image-container">
            <div className="sales-tag">
              <img src={offerTag} alt="Sales Tag" className="sales-tag-image" />
              <span className="sales-tag-text">{percentageDifference}%</span>
            </div>
            <img src={mainImage} alt={name} className="main-image" />
          </div>
        </div>

        <div className="product-info">
          <h1>{name}</h1>
          <p className="product-description">{shortDescription}</p>
          <p className="product-price"><strong>List Price: </strong><s>₹{listingPrice}</s></p>
          <p className="product-selling-price"><strong> Price: </strong>₹{sellingPrice}</p>
          <p className="percentage-difference">Save {percentageDifference}%</p>
          <p className="product-color"><strong>Color: </strong>{selectedColor}</p>
          <p className="product-id"><strong>Product ID: </strong>{productId}</p>
          
          <div className="purchase-buttons">
            <button className="add-to-cart-btn" onClick={handleAddToCart}>Add to Cart</button>
            <button className="buy-now-btn" onClick={handleBuyNow}>Buy Now</button>
            <FaShareAlt className="share-icon" onClick={shareProduct} />
          </div>
          <div className="other-colors">
            <h2>Other Colors Available</h2>
            <div className="colors-container">
              {allColors.map((colorItem, index) => (
                <div key={index} className="color-item" onClick={() => handleColorChange(colorItem.images, colorItem.color)}>
                  <p>{colorItem.color}</p>
                  <img
                    src={`${baseUrl}/${colorItem.images[0]}`}
                    alt={`${name} ${colorItem.color}`}
                    className="color-thumbnail"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="product-details">
            <h3>Details</h3>
            <p>{longDescription}</p>
          </div>
        </div>
      </div>
      <AllReviews productId={productId} />
      <RelatedProducts categoryId={categoryId} currentProductId={productId} />
    </div>
  );
};

export default ProductDetails;
